import * as Sentry from '@sentry/vue'

import useCookieConsent from '@/composables/useCookieConsent'

export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter()
  const {
    public: { sentry }
  } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }

  const cookieConsent = useCookieConsent()

  watch(
    () => cookieConsent.value?.statistics,
    hasStatisticsConsent => {
      const isInitialized = Sentry.isInitialized()
      if (hasStatisticsConsent && !isInitialized) {
        Sentry.init({
          app: nuxtApp.vueApp,
          dsn: sentry.dsn,
          environment: sentry.environment,
          integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration()
          ],
          ignoreErrors: [
            'Unable to preload CSS for',
            'Importing a module script failed.',
            '/_nuxt/builds/meta/',
            'Failed to fetch dynamically imported module:'
          ],
          // Performance Monitoring
          tracesSampleRate: sentry.tracesSampleRate,
          // Session Replay
          replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        })

        Sentry.setTag('renderMode', 'client')
      } else if (!hasStatisticsConsent && isInitialized) {
        Sentry.close(2000)
      }
    }
  )
})
