<template>
  <div>
    <Suspense>
      <NuxtLayout>
        <NuxtPage />
        <VideoPopup />
      </NuxtLayout>
    </Suspense>
  </div>
</template>

<script setup lang="ts">
import { useWebsiteSettings } from '@/state/websiteSettings'

await useWebsiteSettings()
</script>
