<template>
  <NuxtLayout class="is-error-page">
    
    <div class="error-page">
      <WavyDecorationXs class="wavy-decoration md-down" />
      <WavyDecorationMd class="wavy-decoration lg-down md-up" />
      <WavyDecorationLg class="wavy-decoration lg-up" />
      <Decoration404 class="decoration-404" />
      <h2 class="title" v-html="nbsp(t('page-doesnt-exist'))" />
      <p class="text">{{ t('back-to-home') }}</p>
      <BaseButton
        :tag="NuxtLink"
        :to="localePath('/')"
        class="page-button"
        color="secondary"
        @click.prevent.capture="handleBack"
      >
        {{ t('homepage') }}
      </BaseButton>
      <div class="tiles">
        <ChocolateTile class="tile tile--chocolate" />
        <AirplaneTile class="tile tile--airplane" />
        <ZebraTile class="tile tile--zebra" />
        <HeartTile class="tile tile--heart" />
      </div>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { NuxtLink } from '#components'
import Decoration404 from '@/assets/decorations/404.svg?component'
import WavyDecorationLg from '@/assets/decorations/wavy-decoration-404-lg.svg?component'
import WavyDecorationMd from '@/assets/decorations/wavy-decoration-404-md.svg?component'
import WavyDecorationXs from '@/assets/decorations/wavy-decoration-404-xs.svg?component'
import AirplaneTile from '@/assets/icons/tiles/airplane.svg?component'
import ChocolateTile from '@/assets/icons/tiles/chocolate.svg?component'
import HeartTile from '@/assets/icons/tiles/heart.svg?component'
import ZebraTile from '@/assets/icons/tiles/zebra.svg?component'
import { useWebsiteSettings } from '@/state/websiteSettings'

withDefaults(
  defineProps<{
    error: Record<PropertyKey, unknown>
  }>(),
  {
    error: () => ({ statusCode: 500 })
  }
)

const { t } = useI18n()
const localePath = useLocalePath()
const handleBack = () => clearError({ redirect: useLocalePath()('/') })

await useWebsiteSettings()
</script>

<style lang="scss" scoped>
.error-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $dark-brown;
  text-align: center;
}

.wavy-decoration {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: rem(164px);
  filter: drop-shadow(0 8px 12px rgba(0, 0, 0, 0.2));

  @include media-up(md) {
    height: rem(239px);
  }

  @include media-up(lg) {
    height: rem(213px);
  }
}

.decoration-404 {
  height: auto;
  margin-top: rem(141px);
  margin-bottom: rem(37px);

  @include media-up(md) {
    width: rem(427px);
    margin-top: rem(208px);
    margin-bottom: rem(42px);
  }

  @include media-up(lg) {
    width: rem(499px);
    margin-top: rem(118px);
    margin-bottom: rem(40px);
  }
}

.title {
  @include font-size(
    (
      xs: 21px,
      md: 27px
    ),
    1.12
  );
  max-width: rem(257px);
  margin-bottom: rem(15px);
  font-weight: 600;
  letter-spacing: -0.025em;

  @include media-up(md) {
    max-width: rem(482px);
    margin-bottom: rem(13px);
  }

  @include media-up(lg) {
    max-width: rem(438px);
  }
}

.text {
  @include font-size(
    (
      xs: 15px,
      md: 21px
    ),
    1.44
  );
  margin-bottom: rem(37px);
  font-weight: 500;
  letter-spacing: -0.0375em;

  @include media-up(md) {
    margin-bottom: rem(96px);
  }

  @include media-up(lg) {
    margin-bottom: rem(40px);
  }
}

.page-button {
  width: rem(164px);
  margin-bottom: rem(229px);

  @include media-up(md) {
    margin-bottom: rem(228px);
  }

  @include media-up(lg) {
    margin-bottom: rem(124px);
  }
}

.tiles {
  --column-width: #{rem(60px)};
  position: absolute;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-areas:
    'a . .'
    'b c .'
    '. . d';
  grid-template-columns: repeat(3, var(--column-width));

  @include media-up(md) {
    --column-width: #{rem(96px)};
  }

  @include media-up(lg) {
    --column-width: #{rem(85px)};
  }
}

.tile {
  width: 100%;
  height: 100%;

  &--chocolate {
    --background-color: #{$light-brown};
    grid-area: a;
  }

  &--airplane {
    --background-color: #{$light-blue-2};
    grid-area: b;
  }

  &--zebra {
    grid-area: c;
  }

  &--heart {
    grid-area: d;
  }
}
</style>
