<template>
  <button @click="open(videoId, isVimeo)">
    <slot />
  </button>
</template>

<script lang="ts" setup>
defineProps<{ videoId: string; isVimeo?: boolean }>()

const { open } = useVideoPopup()
</script>
