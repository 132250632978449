<template>
  <span v-if="placeholder">
    <slot>{{ link.label }}</slot>
  </span>
  <NuxtLink
    v-else-if="route"
    v-bind="{ to: route, ...(link.target_blank && TARGET_BLANK) }"
    @click.capture="onLinkClick"
  >
    <slot>
      {{ link.label }}
    </slot>
  </NuxtLink>
  <VideoPopupButton
    v-else-if="link.anchor_type === 'video_popup'"
    :video-id="link.video_id"
    :is-vimeo="link.video_is_vimeo"
  >
    <slot>
      {{ link.label }}
    </slot>
  </VideoPopupButton>
  <CalendlyButton v-else-if="link.anchor_type === 'calendly'" :url="href">
    <slot>
      {{ link.label }}
    </slot>
  </CalendlyButton>
  <a
    v-else
    :href="href"
    :download="link.anchor_type === 'attachment'"
    v-bind="{ ...(link.target_blank && TARGET_BLANK) }"
  >
    <slot>
      {{ link.label }}
    </slot>
  </a>
</template>

<script lang="ts" setup>
import type { PublicLink, AnchorTypeEnum } from '@/service/__generated-api'

const props = defineProps<{
  link: PublicLink
  placeholder?: boolean
}>()

const TARGET_BLANK = { target: '_blank', rel: 'noopener noreferrer' }

const href = computed(() =>
  props.link.anchor_type === 'attachment'
    ? props.link.attachment_file
    : props.link.anchor_type === 'url' || props.link.anchor_type === 'calendly'
    ? props.link.url
    : ''
)

const hash = computed(() => {
  const { widget, subsection } = props.link

  return widget
    ? subsection
      ? `#subsection-${subsection}`
      : `#widget-${widget}`
    : ''
})

const route = computed(() => {
  const { anchor_type, slug } = props.link
  const isHomePage = slug === 'home'

  const routeNameByType: Partial<Record<AnchorTypeEnum, string>> = {
    simplepage: isHomePage ? 'index' : 'slug',
    news: 'news-slug'
  }

  const name = routeNameByType[anchor_type]

  if (name) {
    const params = isHomePage ? undefined : { slug }

    return useLocalePath()({ name, params, hash: hash.value })
  }

  return undefined
})

const onLinkClick = (e: Event) => {
  if (hash.value) {
    const el = document.querySelector(hash.value)

    if (el) {
      e.preventDefault()
      const { top } = el.getBoundingClientRect()

      window.scrollTo(
        0,
        window.scrollY + top - parseInt(getComputedStyle(el).scrollMarginTop)
      )
    }
  }
}
</script>
