import type { Directive, DirectiveBinding } from 'vue'

type Mode = 'fade' | 'fade-translate'

type Options = {
  mode: Mode
  enabled: boolean
  lazyHydrated?: boolean
}

type BindingValue = undefined | Partial<Options>

const defaultOptions: Options = {
  mode: 'fade-translate',
  enabled: true,
  lazyHydrated: false
}

const parseOptions = (binding: DirectiveBinding<BindingValue>) => ({
  ...defaultOptions,
  ...binding.value
})

export default defineNuxtPlugin(nuxtApp => {
  useHead({
    style: [
      {
        key: 'viewport-animated',
        innerHTML: `
        .viewport-animated--fade {
          opacity: 0;
          transition: opacity 0.5s;
        }

        .viewport-animated--fade-active {
          opacity: 1;
        }

        .viewport-animated--fade-translate {
          opacity: 0;
          transition: opacity 0.5s, transform 0.5s;
          transform: translateY(20px);
        }

        .viewport-animated--fade-translate-active {
          opacity: 1;
          transform: translateY(0);
        }
        `
      }
    ],
    noscript: [
      {
        key: 'viewport-animated',
        innerHTML: `<style>
          .viewport-animated {
            opacity: 1 !important;
            transform: unset !important;
          }
        </style>`
      }
    ]
  })

  const directive: Directive<HTMLElement, BindingValue> = {
    created(el, binding) {
      const { enabled, mode } = parseOptions(binding)

      if (enabled) {
        el.classList.remove('viewport-animated')
        el.classList.remove(`viewport-animated--${mode}`)
      }
    },
    mounted(el, binding) {
      const { enabled, mode } = parseOptions(binding)
      if (enabled) {
        el.classList.add('viewport-animated')
        el.classList.add(`viewport-animated--${mode}`)

        const animatedElements = [
          ...(el.parentElement?.querySelectorAll('.viewport-animated') || [])
        ]

        el.style.transitionDelay = `${200 * animatedElements.indexOf(el)}ms`

        new IntersectionObserver((entries, animateOnScrollObserver) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add(`viewport-animated--${mode}-active`)
              animateOnScrollObserver.unobserve(entry.target)
            }
          })
        }).observe(el)
      }
    },
    getSSRProps(binding) {
      const { enabled, mode } = parseOptions(binding)
      return enabled
        ? { class: `viewport-animated viewport-animated--${mode}` }
        : {}
    }
  }

  nuxtApp.vueApp.directive('viewport-animated', directive)
})
