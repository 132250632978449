export default function useModal(modalRef: Ref<HTMLElement | undefined>) {
  const scrollY = useState<number>('scrollY', () => 0)

  const stopPropagation = (e: Event) => {
    e.stopPropagation()
  }

  const blockScroll = () => {
    if (process.server) return
    scrollY.value = window.pageYOffset

    document.body.style.setProperty('overflow', 'hidden')
    modalRef.value?.style.setProperty('overscroll-behavior', 'contain')
    modalRef.value?.addEventListener('pointermove', stopPropagation)
  }

  const unblockScroll = () => {
    if (process.server) return
    document.body.style.removeProperty('overflow')

    modalRef.value?.removeEventListener('pointermove', stopPropagation)

    // restore scroll position
    window.scrollTo(0, scrollY.value)
  }

  onUnmounted(() => {
    unblockScroll()
  })

  return {
    blockScroll,
    unblockScroll
  }
}
