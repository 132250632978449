<template>
  <div class="cookie-notice cookieconsent-optout-marketing">
    <div
      class="text"
      v-html="
        t('cookies.enable-marketing-cookies', '', {
          list: [contentUrl]
        })
      "
    />
    <BaseButton class="btn" color="secondary" @click="changePreferences">
      {{ t('cookies.change-preferences') }}
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
defineProps<{ contentUrl: string }>()

const { t } = useI18n()

const changePreferences = () => {
  if (
    'Cookiebot' in window &&
    typeof window.Cookiebot === 'object' &&
    window.Cookiebot &&
    'show' in window.Cookiebot &&
    typeof window.Cookiebot.show === 'function'
  )
    window.Cookiebot.show()
}
</script>

<style lang="scss" scoped>
.cookie-notice {
  padding: rem(40px);
  margin-bottom: auto;
  color: $dark-brown;
  background-color: $beige;

  :deep(a) {
    text-decoration: underline;
  }
}

.text {
  margin-bottom: rem(20px);
}

.btn {
  width: rem(200px);
}
</style>
