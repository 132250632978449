import markerSDK, { type MarkerSdk } from '@marker.io/browser'

export default defineNuxtPlugin(async () => {
  const {
    public: { MARKER_ID }
  } = useRuntimeConfig()

  let marker: MarkerSdk | undefined
  if (MARKER_ID) {
    marker = await markerSDK.loadWidget({
      project: MARKER_ID
    })
  }

  return {
    provide: {
      marker
    }
  }
})
