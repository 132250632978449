import { createGtm } from '@gtm-support/vue-gtm'
import VueGtag from 'vue-gtag'
import type { Router } from 'vue-router'

export default defineNuxtPlugin(async nuxtApp => {
  const {
    public: { GTM_ID, GTAG_ID }
  } = useRuntimeConfig()
  const { data: seoSettings } = await useAsyncData(() =>
    useApi().publicCoreSeoSettingsRetrieve()
  )

  // GTM
  if (seoSettings.value?.gtm_id ?? GTM_ID) {
    nuxtApp.vueApp.use(
      createGtm({
        id: seoSettings.value?.gtm_id ?? GTM_ID,
        defer: false,
        compatibility: false,
        enabled: true,
        debug: true,
        loadScript: true,
        vueRouter: useRouter(),
        trackOnNextTick: false
      })
    )
  }

  // GTAG
  if (seoSettings.value?.gtag_id ?? GTAG_ID) {
    const cookieConsent = useCookieConsent()
    watch(
      () => cookieConsent.value?.marketing && cookieConsent.value.statistics,
      hasConsent => {
        if (hasConsent) {
          nuxtApp.vueApp.use(
            VueGtag,
            {
              config: {
                id: seoSettings.value?.gtag_id ?? GTAG_ID
              }
            },
            nuxtApp.$router as Router
          )
        } else {
          window.location.reload()
        }
      }
    )
  }
})
