import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_kbDwczzjxS from "/app/modules/httpService/plugin.ts";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import marker_client_ZO2wf4qqoQ from "/app/plugins/marker.client.ts";
import route_change_client_LzBG2zCLpy from "/app/plugins/route-change.client.ts";
import router_scroll_behavior_kF4FQNFKc8 from "/app/plugins/router-scroll-behavior.ts";
import scroll_client_HJUo2UKx9n from "/app/plugins/scroll.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import trackers_FpmZMuzECg from "/app/plugins/trackers.ts";
import viewport_animated_7NCuNVEXmZ from "/app/plugins/viewport-animated.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  plugin_kbDwczzjxS,
  chunk_reload_client_UciE0i6zes,
  marker_client_ZO2wf4qqoQ,
  route_change_client_LzBG2zCLpy,
  router_scroll_behavior_kF4FQNFKc8,
  scroll_client_HJUo2UKx9n,
  sentry_client_shVUlIjFLk,
  trackers_FpmZMuzECg,
  viewport_animated_7NCuNVEXmZ
]