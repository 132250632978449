import type { CookieConsent } from '@/types/utils'

const validateConsentData = (data: unknown): data is CookieConsent =>
  typeof data === 'object' &&
  data !== null &&
  'necessary' in data &&
  'statistics' in data &&
  'preferences' in data &&
  'marketing' in data &&
  typeof data.marketing === 'boolean' &&
  typeof data.necessary === 'boolean' &&
  typeof data.preferences === 'boolean' &&
  typeof data.statistics === 'boolean'

const parseValue = (value: string) => {
  if (value === 'false') return false
  if (value === 'true') return true
  // we don't need properties of other types for now
  return null
}

// CookieBot uses JS objects like this
// {preferences:false,region:'pl',ver:1}
// instead of JSON
// {"preferences":false,"region":"pl","ver":1}
//
// scroll to the bottom for server side usage
// https://www.cookiebot.com/en/developer/
const parseJSObjectFromString = (input: string): object =>
  Object.fromEntries(
    input
      .replace('{', '')
      .replace('}', '')
      .split(',')
      .map(property => {
        const [key, value] = property.split(':')
        return [key, parseValue(value)]
      })
      .filter(([, value]) => value !== null)
  )

export default function parseConsent(cookie: string): null | CookieConsent {
  const data = parseJSObjectFromString(decodeURIComponent(cookie))
  return validateConsentData(data) ? data : null
}
