<template>
  <BasePopup :active="!!videoId" @close="close">
    <VideoIframe :video-id="videoId" :is-vimeo="videoIsVimeo" />
  </BasePopup>
</template>

<script lang="ts" setup>
import { onClickOutside, onKeyStroke } from '@vueuse/core'

const { videoId, videoIsVimeo, close } = useVideoPopup()

const popupContent = ref<HTMLElement>()
onClickOutside(popupContent, close)
onKeyStroke('Escape', close)
</script>
