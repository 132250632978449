export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"key":"description","name":"description","content":""},{"key":"keywords","name":"keywords","content":""},{"key":"og:title","property":"og:title","content":""},{"key":"og:description","property":"og:description","content":""},{"name":"robots","content":"index, follow"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"preconnect","href":"https://backend.fabrykaczekolady.pl"}],"style":[],"script":[],"noscript":[{"key":"base-image","innerHTML":"\n          <style>\n            .image {\n              opacity: 1 !important;\n            }\n          </style>\n          "}],"title":"Default title","titleTemplate":"% | Sparing-CMS"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false