<template>
  <Teleport to="body">
    <Transition name="fade">
      <div v-if="active" ref="popup" class="popup">
        <button class="close" @click="close">
          <CloseIcon class="close-icon" />
        </button>

        <div ref="popupContent" class="content">
          <slot />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import CloseIcon from '@/assets/icons/close.svg?component'

const props = defineProps<{
  active: boolean
}>()

const emit = defineEmits(['close'])

function close() {
  emit('close')
}

const { popup, popupContent } = usePopup({ active: () => props.active, close })
</script>

<style lang="scss" scoped>
$close-size: var(--close-size);

.popup {
  --close-size: #{rem(57px)};
  @include fill-space($position: fixed);
  inset: 0;
  z-index: z(popup);
  align-items: center;
  padding-top: $close-size;
  overflow-y: auto;
  background: $black;

  @include media-up(md) {
    --close-size: #{rem(64px)};
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.close {
  @include size($close-size);
  position: fixed;
  top: 0;
  right: 0;
  z-index: z(popup) + 1;
  background: $orange;
}

.close-icon {
  @include size(rem(18px));
  color: $beige;

  @include media-up(md) {
    @include size(rem(20px));
  }

  .contrast-mode & {
    color: $contrast-black;
  }
}
</style>
