export function useVideoPopup() {
  const videoId = useState<string>('popupVideoId', () => '')
  const videoIsVimeo = useState('popupVideoIsVimeo', () => false)

  const open = (id: string, isVimeo = false) => {
    videoId.value = id
    videoIsVimeo.value = isVimeo
  }

  const close = () => {
    videoId.value = ''
    videoIsVimeo.value = false
  }

  return {
    open,
    close,
    videoId,
    videoIsVimeo
  }
}
