import type { CookieConsent } from '@/types/utils'

export default function useCookieConsent() {
  const {
    public: { COOKIEBOT_ID }
  } = useRuntimeConfig()
  const cookie = useCookie('CookieConsent')
  return computed<CookieConsent | null>(() => {
    if (!COOKIEBOT_ID)
      return {
        marketing: true,
        necessary: true,
        preferences: true,
        statistics: true
      }
    return cookie.value ? parseConsent(cookie.value) : null
  })
}
