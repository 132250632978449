import { onClickOutside, onKeyStroke } from '@vueuse/core'
import type { WatchSource } from 'vue'

export default function usePopup({
  active,
  close
}: {
  active: WatchSource<boolean>
  close: () => void
}) {
  const popup = ref<HTMLElement>()
  const { blockScroll, unblockScroll } = useModal(popup)

  watch(active, isActive => {
    if (isActive) {
      blockScroll()
    } else {
      unblockScroll()
    }
  })

  const popupContent = ref<HTMLElement>()
  onClickOutside(popupContent, close)
  onKeyStroke('Escape', close)

  return { popup, popupContent }
}
